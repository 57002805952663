<template>
  <div ref="el" class="ai-message pt-md">
    <div v-if="isUserMessage" class="align-self-flex-end ml-xxl">
      <div class="bg-surface-neutral-2 rounded p-md">
        {{ aiMessage.data.message }}
      </div>
    </div>
    <div v-else class="align-self-flex-start flex-column align-items-flex-end mr-xxl">
      <ai-chat-card :in-progress="inProgress">
        <MarkdownRenderer :source="aiMessage.data.message" />
        <ChartCard v-if="chartState" class="mt-lg" :chart="chartState" @click="scrollToMessage" />
        <ProgressBar v-if="inProgress" class="mt-lg" :progress="aiMessage.progress" />
      </ai-chat-card>
      <FeedbackButtons v-if="aiMessage.message_id" class="mt-sm" :score="aiMessage.score" @update="onUpdateScore" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import AiMessage from "@/ai-assistant/ai-message";
import aiChatCard from "@/ai-assistant/ai-chat-card.vue";
import { buildChartState, scrollMessageComponentViewToTop } from "@/ai-assistant/ai-utils";
import { useIsMobile } from "@/composables/is-mobile";
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import ChartCard from "@/ai-assistant/chart-card.vue";
import FeedbackButtons from "@/ai-assistant/feedback-buttons.vue";
import { useCreateAiMessageScore } from "@/ai-assistant/ai-assistant-api";
import MarkdownRenderer from "@/components/markdown-renderer.vue";
import ProgressBar from "@/ai-assistant/progress-bar.vue";

const el = ref<HTMLElement | null>(null);

const props = defineProps<{
  aiMessage: AiMessage;
}>();

const store = useStore();

const isMobile = useIsMobile();

const createAiMessageScore = useCreateAiMessageScore();

const isUserMessage = computed(() => props.aiMessage.sender !== "ai");

const chartState = computed(() => {
  if (props.aiMessage.data.chart && props.aiMessage.data.filterConfiguration) {
    return buildChartState(props.aiMessage.data.chart, props.aiMessage.data.filterConfiguration);
  }
  return null;
});

const sessionId = computed(() => store.state.aiAssistant.session_id);

const inProgress = computed<boolean>(() => !!(props.aiMessage.progress && props.aiMessage.progress < 1));

const scrollToMessage = () => {
  scrollMessageComponentViewToTop(props.aiMessage.id);

  if (isMobile.value) {
    setTimeout(() => {
      if (open) {
        store.commit(Mutations.closeAiAssistant);
      }
    }, 500);
  }
};

onMounted(() => {
  if (isUserMessage.value) {
    setTimeout(() => {
      el.value.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  }
});

const onUpdateScore = ({ score, comment = null }: { score: number; comment?: string }) => {
  createAiMessageScore.mutate({
    session_id: sessionId.value,
    message_id: props.aiMessage.message_id,
    score,
    comment,
  });
  store.commit(Mutations.updateAiMessageScore, { id: props.aiMessage.id, score });
};
</script>

<style scoped>
.ai-message:last-child {
  @media screen and (min-width: 992px) {
    min-height: 100%;
  }
}
</style>
