
h3 {
  margin: 20px 0;
}

.table-controls {
  margin-bottom: 10px;
}

table.table {
  margin-bottom: 0;

  td.actions {
    a:not(:last-child) {
      margin-right: 5px;
    }

    a {
      cursor: pointer;
    }
  }
}
