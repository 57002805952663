<template>
  <div class="zoined-snippet">
    <div class="content" :class="{ loading: loading, 'overflow-hidden': overflowHidden }">
      <spinner v-show="loading"></spinner>
      <p v-if="error" class="chart-error">{{ error }}</p>
      <div v-if="snippetHtml && !loading && !error" @click="onSnippetClick" v-html="snippetHtml" />
    </div>
    <div v-if="pagingInfo && !loading && !error" class="pagination-control">
      <pagination
        v-if="paginationEnabled"
        :page="pagingInfo.page"
        :total-pages="pagingInfo.totalPages"
        @page-change="switchPage"
      ></pagination>
      <limit-selector
        v-if="paginationEnabled"
        :limit="filters.limit"
        :limit-options="limitOptions"
        :append-to-body="!fullScreen"
        :dropup="fullScreen"
        :menu-right="fullScreen"
        @update="$emit('updateLimit', $event)"
      ></limit-selector>
    </div>
  </div>
</template>

<script>
import pagination from "../components/pagination.vue";
import spinner from "../components/spinner.vue";
import cloneDeep from "clone";
import SnippetFetcher from "../lib/data/snippet";
import limitSelector from "../components/limit-selector";
import PortalContext from "../lib/data/portal_context";
import { exportReport } from "../lib/export";

function toInt(i) {
  if ("number" == typeof i) {
    return i;
  } else if ("string" == typeof i) {
    try {
      return parseInt(i);
    } catch {
      return 0;
    }
  } else {
    return 0;
  }
}

export default {
  components: {
    pagination,
    spinner,
    limitSelector,
  },
  props: {
    pagination: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    filterConfiguration: {
      type: Object,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: null,
    },
    tableConfig: {
      type: Object,
      default: null,
    },
    overflowHidden: {
      type: Boolean,
      default: false,
    },
    component: {
      type: Object,
      default: null,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["data", "updateLimit", "pageChanged"],
  data() {
    return {
      currentFilters: this.filters,
      loading: false,
      fetchIndex: 0,
      error: null,
      json: null,
      snippetHtml: null,
    };
  },
  computed: {
    paginationEnabled: function() {
      return this.pagination;
    },
    pagingInfo: function() {
      if (this.pagination && this.json && this.json.paging) {
        const paging = this.json.paging;
        const offset = toInt(paging.offset) || 0;
        const limit = toInt(paging.limit) || 10;
        return {
          page: Math.floor(offset / limit) + 1,
          totalPages: Math.ceil(paging.total_items / limit),
          pageSize: limit,
        };
      }
      return null;
    },
    limitOptions() {
      return [10, 20, 50, 100, 200];
    },
  },
  watch: {
    filters(filters, old) {
      if (!_.isEqual(filters, old)) {
        this.fetchData(filters).then((success) => {
          if (success) {
            this.drilldownStack = [];
          }
        });
      }
    },
    chartOptions(options, old) {
      if (!_.isEqual(options, old)) {
        this.fetchData(this.currentFilters);
      }
    },
    tableConfig(options, old) {
      if (!_.isEqual(options, old)) {
        this.fetchData(this.currentFilters);
      }
    },
    pagingInfo(info) {
      this.$emit("pageChanged", cloneDeep(info, true));
    },
    type() {
      this.fetchData(this.filters).then((success) => {
        if (success) {
          this.drilldownStack = [];
        }
      });
    },
  },
  mounted: function() {
    if (this.component?.request_params) {
      // predefined params and chart_token with params digest for public pages
      const { filter, chart_options, table_config } = this.component.request_params;
      const context = new PortalContext(this.component.chart_token, I18n.locale);
      this.fetchData(filter, chart_options, table_config, context);
    } else if (this.filters) {
      this.fetchData(this.filters);
    }
  },
  methods: {
    fetchData: function(filters, chartOptions = null, tableConfig = null, context = null) {
      this.fetchIndex++;
      this.loading = true;
      const ongoingIndex = this.fetchIndex;
      chartOptions =
        chartOptions ||
        Object.assign({}, this.chartOptions, {
          static: this.component ? !!this.component.static : false,
        });
      tableConfig = tableConfig || this.tableConfig || {};
      context = context || PortalContext.global();
      return new SnippetFetcher(context)
        .fetchJson(this.type, filters, true, chartOptions, tableConfig)
        .then((json) => {
          // Only process results if this is latest query we have started
          // This discards multiclicks
          if (ongoingIndex == this.fetchIndex) {
            this.loading = false;
            this.error = null;
            this.currentFilters = filters;
            this.json = json;
            this.snippetHtml = json.html;
            this.$emit("data", json);
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          if (ongoingIndex == this.fetchIndex) {
            this.loading = false;
            this.error = err.message;
          }
          return false;
        });
    },
    switchPage: function(page) {
      const f = cloneDeep(this.currentFilters);
      f.offset = (page - 1) * (this.json.paging.limit || 10);

      this.fetchData(f).then((success) => {
        if (success) {
          this.currentFilters = f;
        }
      });
    },
    onSnippetClick(evt) {
      if (evt.target.classList.contains("download-excel-button")) {
        // Handle excel exports on the background via sidekiq worker
        evt.preventDefault();
        evt.stopPropagation();
        this.exportXlsx();
      }
    },
    exportXlsx() {
      exportReport(this.type, {
        filterConfiguration: this.filterConfiguration,
        chartOptions: this.chartOptions,
        tableConfig: this.tableConfig,
        format: "xlsx",
      });
    },
  },
};
</script>

<style scoped>
.zoined-snippet {
  position: relative;
  height: 100%;
  min-height: 100px;
}

.content {
  height: 100%;
  overflow-x: auto;
}

.content.overflow-hidden {
  overflow-x: hidden;
}

.content.paginated {
  height: calc(100% - 30px);
}

.pagination-control {
  height: 30px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.pagination-control .pagination {
  margin: 0;
}

.pagination-control .limit-selector {
  margin-left: 5px;
}
</style>
